<template>
  <de-button
    class="tw-justify-between tw-px-2.5 hover:tw-ring-primary-700 hover:tw-bg-primary-700"
    :label="userStore.user.currencyShortName"
    :icon-pos="ButtonIconPositionOptions.right"
    icon="chevron-down-filled"
    icon-class="tw-w-300 tw-h-300 tw-ml-1"
    @click="toggleTabState(true)"
  />

  <de-sidebar v-model:visible="isVisible" :show-close-icon="false" position="right">
    <template #header>
      <div class="tw-flex">
        <button class="tw-mr-6" @click="toggleTabState(false)">
          <de-svg-icon
            name="chevron-down"
            class="tw-w-600 tw-h-600 tw-text-primary-400 tw-transform tw-rotate-90"
          />
        </button>

        <div class="heading-h3">
          {{ $t('common.selectCurrency') }}
        </div>
      </div>
    </template>

    <select-currency-list class="tw--mt-2.5" @currency-change="toggleTabState(false)" />
  </de-sidebar>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SelectCurrencyList from '~/shared/components/select-currency/SelectCurrencyList.vue';
import DeSidebar from '~/shared/components/lib/sidebar/DeSidebar.vue';
import { ButtonIconPositionOptions } from '~/shared/components/lib/button/button.entity';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import { useUserStore } from '~/store/user';

const userStore = useUserStore();

const isVisible = ref(false);

const toggleTabState = (isOpen: boolean) => {
  isVisible.value = isOpen;
};
</script>
